$app-color-black: #000000;
$app-color-black-1: #222222;
$app-color-boxshadow: #00000029;
$app-color-border-black: #083446;
$app-color-white: #ffffff;
$app-color-white-1: #e9ecef;
$app-color-red: #bb2328;
$app-color-red-1: #f44336;
$app-color-red-text: #e24e53;
$app-color-orange-1: #fa896b;
$app-color-orange-2: #e1604b;
$app-color-green: #75c74b;
$app-color-blue: #4c6fff;
$app-light-blue: #f2f5ff;
$app-light-blue-1: #aef1cb;
$app-color-blue-1: #7084c7;
$app-color-blue-2: #58caea;
$app-color-blue-3: #edf2f7;
$app-color-blue-4: #f2f9ff;
$app-color-blue-5: #f0f4fc;
$app-color-yellow-1: #f7b84b;
$app-light-yellow-1: #fff3f0;
$app-light-yellow-2: #fff7e9;
$brand-primary: #eeeeee;
$app-gray-theme: #1b1e26;
$app-color-hitgray: #a2b2b9;
$app-color-gray-lighter: #eceeef;
$app-color-friargray: #82807a;
$app-inactive-color: #9f9f9f;
$app-color-gray-1: #f3f3f9;
$app-color-gray-2: #333333;
$app-color-gray-3: #495057;
$app-color-gray-4: #b6bfc9;
$app-color-gray-5: #8390a3;
$app-color-gray-6: #e3e3e3;
$app-color-gray-7: #30333b;
$app-color-gray-8: #78797d;
$app-color-gray-9: #eff0f0;
$app-color-gray-10: #bdbdbd;
$app-color-gray-11: #2e3230;
$app-color-gray-12: #1e20251f;
$app-color-gray-13: #f6f6f6;
$app-color-gray-14: #969696;
$app-color-gray-15: #dddddd;
$app-color-gray-16: #f7f7fc;
$app-color-gray-17: #a6aabe;
$app-color-gray-18: #f7f7f7;
$app-color-gray-19: #f3f4f4;
$app-color-gray-20: #666666;
$app-color-gray-21: #818181;
$app-color-gray-22: #8f8f8f;
$app-color-gray-23: #dfdfdf;
$app-color-gray-24: #eaecf3;
$app-color-gray-25: #bbbec3;
$app-color-gray-26: #555555;
$app-color-gray-27: #777777;
$app-color-gray-28: #b7b7b7;
$app-color-gray-29: #5c5c5c;
$app-color-gray-30: #AFAFAF;
$app-color-gray-31: #DADADA;
$app-color-gray-32: #E0E0E0;
$app-color-gray-33: #464646;
$app-color-gray-34: #F0F4FB;
$app-color-gray-35: #F8FAFB;
$app-color-gray-36: #767676;
$app-color-gray-37: #787878;
$app-color-orange: #ee5339;
$table-color1: #e5f5f8;
$table-color2: #0086a4;
$table-color3: #eef1f8;
$table-color4: #465ea4;
$table-color5: #fff9ed;
$table-color6: #cc8800;
$table-color7: #faeeee;
$table-color8: #e65f74;
$table-color9: #ecf4ee;
$table-color10: #6fb47e;
$table-color11: #ddf0fd;
$table-color12: #1b79bc;
$unit-economics-clor1: #c8c8c8;
$unit-economics-clor2: #646464;
$unit-economics-clor3: #e8e8e8;
$unit-economics-clor4: #dadada;
$unit-economics-clor5: #e9ebec;
$unit-economics-clor6: #f4f6ff;
$unit-economics-clor7: #979b99;
$unit-economics-clor8: #f5f5f5;
$unit-economics-clor9: #1e1e1e;
$unit-economics-clor10: #616161;
$unit-economics-clor11: #00961e;
$unit-economics-clor12: #ededed;
$unit-economics-clor13: #e9e9e9;
$unit-economics-clor14: #585858;
$unit-economics-clor15: #7B7B7B;
$unit-economics-clor16: #95A9FA;
$unit-economics-clor17: #4C6FFF;

$background-color_2: #0073EA;
$background-color_3: #f1f2f3;
$background-color_4: #D0D4E4;
$unit-economics-clor18: #FAFAFA;

$client-view-clor1: #0003;
$client-view-clor2: #0004;
$client-view-clor3: #00000024;
$client-view-clor4: #0000001f;
$client-view-clor5: #f5f3f3;
$client-view-clor6: #00000033;
$client-view-clor7: #00000024;
$client-view-clor8: #00000013;
$client-view-clor9: #ccc;
$client-view-clor10: #323232;
$client-view-clor11: #dcdcdc;
$client-view-clor12: #F1F1F1;
$client-view-clor13: #212529;
$client-view-clor14: #1E1E1E;
$client-view-clor15: #fb6464;
$client-view-clor16: #1E202512;
$client-view-clor17: #fa7f7f00;
$client-view-clor18: #ff6400;
$client-view-clor19: #1E202512;
$client-view-clor20: #0000001a;
$client-view-clor22: #FFFFFF;
$client-view-clor23: #E8E8E8;
$client-view-clor24: #EFEFEF;
$client-view-clor25: #c1c1c1;
$client-view-clor26: #0000000a;
$client-view-clor27: #3bbfcb;
$client-view-clor28: #d9d9d9;
$client-view-clor29: #F0F0F0;
$client-view-clor30: #BBBEC3;
$client-view-clor31: #ebebeb;
$client-view-clor32: #FFFEE0;
$client-view-clor33: #D2D2D2;
$client-view-clor34: #29badb;
$client-view-clor35: #29badb1a;
$client-view-clor36: #f171711a;
$client-view-clor37: #38414a26;
$client-view-clor38: #0000000d;

$app-color-cement:#cccccc;
