@mixin breakpoint($size, $orientation: "") {
    // extra small device screen
    @if $size==xs {
        @media only screen and (max-width: 599px) {
            @content;
        }
    }
    @if $size==xs-960 {
        @media only screen and (max-width: 959.9px) {
            @content;
        }
    }
    // Small device / mobile screen (portrait)
    @else if $size==sm {
        @media only screen and (min-width: 600px) and (max-width: 959px) {
            @content;
        }
    }
    // Mobile devices (landscape)
    @else if $size==sm and $orientation==landscape {
        @media (orientation: landscape) and (max-width: 959.9px) {
            @content;
        }
    }
    // Medium device screen (portrait)
    @else if $size==md {
        @media (min-width: 960px) and (max-width: 1279px) {
            @content;
        }
    }
    // Medium device screen - min width 1024px
    @else if $size==md-1024 {
        @media only screen and (min-width: 1024px) and (max-width: 1279px) {
            @content;
        }
    }
    // Medium device / Tablet screen (landscape)
    @else if $size==md and $orientation==landscape {
        @media (orientation: landscape) and (min-width: 960px) and (max-width: 1279.9px) {
            @content;
        }
    }
    // Large device screen
    @else if $size==lg {
        @media only screen and (min-width: 1280px) and (max-width: 1919px) {
            @content;
        }
    }
    @else if $size==lg-max {
        @media only screen and (min-width: 1280px) {
            @content;
        }
    }
    // Large device screen - min width 1280px
    @else if $size==lg-max {
        @media only screen and (min-width: 1280px) {
            @content;
        }
    }
    // Large device screen - min width 1280px
    @else if $size==lg-1280 {
        @media only screen and (min-width: 1280px) and (max-width: 1321px) {
            @content;
        }
    }
    // Large device screen - min width 1322px
    @else if $size==lg-1322 {
        @media only screen and (min-width: 1322px) and (max-width: 1359px) {
            @content;
        }
    }
    // Large device screen - min width 1360px
    @else if $size==lg-1366 {
        @media only screen and (min-width: 1360px) and (max-width: 1439px) {
            @content;
        }
    }
    @else if $size==lg-1440 {
        @media only screen and (min-width: 1440px) and (max-width: 1599px) {
            @content;
        }
    }
    // Large device screen (landscape)
    @else if $size==lg and $orientation==landscape {
        @media only screen and (orientation: landscape) and (min-width: 1280px) and (max-width: 1919.9px) {
            @content;
        }
    }
    // Large device screen - min width 1600px
    @else if $size==lg-1600 {
        @media only screen and (min-width: 1600px) and (max-width: 1679px) {
            @content;
        }
    }
    @else if $size==lg-1680 {
        @media only screen and (min-width: 1680px) and (max-width: 1919px) {
            @content;
        }
    }
    // Extra large device screen
    @else if $size==xl-1920 {
        @media only screen and (min-width: 1920px) {
            @content;
        }
    }

    @else if $size==xl {
        @media only screen and (min-width: 1920px) and (max-width: 2039px) {
            @content;
        }
    }
    @else if $size==xl-2048 {
        @media only screen and (min-width: 2048px) and (max-width: 2559px) {
            @content;
        }
    }
    // Extra Large device screen - min width 2560px
    @else if $size==xl-2560 {
        @media only screen and (min-width: 2560px) and (max-width: 5000px) {
            @content;
        }
    }
    // Extra large device screen - min width 1600px
    @else if $size==xl-max {
        @media only screen and (min-width: 1600px) {
            @content;
        }
    }
    // Ipad Screen //
    // IPad / Tablet screen
    @else if $size==ipad {
        @media only screen and (min-width: 768px) and (max-width: 1023.9px) {
            @content;
        }
    }
    // iPad / Tablet screen (landscape)
    @else if $size==ipad and $orientation==landscape {
        @media (orientation: landscape) and (min-width: 768px) and (max-width: 1023.9px) {
            @content;
        }
    }
    // Ipad Pro
    @else if $size==ipad-pro {
        @media only screen and (min-width: 1024px) and (max-width: 1365.9px) {
            @content;
        }
    }
    // iPad / Tablet screen (landscape)
    @else if $size==ipad-pro and $orientation==landscape {
        @media (orientation: landscape) and (min-width: 1366px) and (max-width: 1366px) {
            @content;
        }
    }
    /**
    *
    * Window height screen media range
    * With max height
    *
    */
    // Window screen - min height 600px
    @else if $size==h-600-max {
        @media only screen and (min-height: 600px) and (max-height: 692.9px) {
            @content;
        }
    }
    @else if $size==h-691-max {
        @media only screen and (min-height: 691px) and (max-height: 719px) {
            @content;
        }
    }
    // Window screen - min height 720px
    @else if $size==h-720-max {
        @media only screen and (min-height: 720px) and (max-height: 882.9px) {
            @content;
        }
    }
    // Window screen - min height 883px
    @else if $size==h-883-max {
        @media only screen and (min-height: 883px) and (max-height: 899px) {
            @content;
        }
    }
    // Window screen - min height 900px
    @else if $size==h-900-max {
        @media only screen and (min-height: 900px) and (max-height: 946.9px) {
            @content;
        }
    }
    // Window screen - min height 947px
    @else if $size==h-947-max {
        @media only screen and (min-height: 947px) and (max-height: 1023px) {
            @content;
        }
    }
    // Window screen - min height 1024px
    @else if $size==h-1024-max {
        @media only screen and (min-height: 1024px) and (max-height: 1049px) {
            @content;
        }
    }
    // Window screen - min height 1050px
    @else if $size==h-1050-max {
        @media only screen and (min-height: 1050px) and (max-height: 1079px) {
            @content;
        }
    }
    // Window screen - min height 1080px
    @else if $size==h-1080-max {
        @media only screen and (min-height: 1080px) and (max-height: 1122px) {
            @content;
        }
    }
    // Window screen - min height 1123px
    @else if $size==h-1123-max {
        @media only screen and (min-height: 1123px) and (max-height: 1199px) {
            @content;
        }
    }
    // Window screen - min height 1200px
    @else if $size==h-1200-max {
        @media only screen and (min-height: 1200px) and (max-height: 1439px) {
            @content;
        }
    }
    // Window screen - min height 1440px
    @else if $size==h-1440-max {
        @media only screen and (min-height: 1440px) and (max-height: 1535px) {
            @content;
        }
    }
    /**
    *
    * Window height screen media range
    * Without max height
    *
    */
    // Window screen - min height 600px
    @else if $size==h-600 {
        @media only screen and (min-height: 600px) {
            @content;
        }
    }
    // Window screen - min height 720px
    @else if $size==h-720 {
        @media only screen and (min-height: 720px) {
            @content;
        }
    }
    @else if $size==max-h-600 {
        @media only screen and (max-height: 600px) {
            @content;
        }
    }
    // Window screen - min height 900px
    @else if $size==h-900 {
        @media only screen and (min-height: 900px) {
            @content;
        }
    }
    // Window screen - min height 1024px
    @else if $size==h-1024 {
        @media only screen and (min-height: 1024px) {
            @content;
        }
    }
    // Window screen - min height 1050px
    @else if $size==h-1050 {
        @media only screen and (min-height: 1050px) {
            @content;
        }
    }
    // Window screen - min height 1080px
    @else if $size==h-1080 {
        @media only screen and (min-height: 1080px) {
            @content;
        }
    }
    // Window screen - min height 1200px
    @else if $size==h-1200 {
        @media only screen and (min-height: 1200px) {
            @content;
        }
    }
    // Window screen - min height 1440px
    @else if $size==h-1440 {
        @media only screen and (min-height: 1440px) {
            @content;
        }
    }
    // Window screen - min height 1536px
    @else if $size==h-1536 {
        @media only screen and (min-height: 1536px) {
            @content;
        }
    }
    // Safari Browser
    // @else if $size==safari {
    //     @media not all and (min-resolution: .001dpcm) {
    //         @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    //             @content;
    //         }
    //     }
    // }
    // Unsupported screen range
    @else {
        @warn "Breakpoint mixin supports: xs, xs-*, sm, sm-*, md, md-*, lg, lg-*, xl, xl-*, h-*";
    }
}
