@import "./variables.scss";
@import "./scrollbar.scss";
@import "./common.scss";
@import "./mixins/input.scss";
@import "./mixins/button.scss";
@import "./mixins/breakpoint.scss";

:root {
  --dh-grey: #{$app-color-friargray};
}

* {
  -webkit-tap-highlight-color: transparent;
}

$colors: (
  timesheet-app-theme: $app-color-red-text,
);

@mixin color-modifiers {

  @each $name,
  $hex in $colors {
    --data-color-#{$name}: #{$hex};
  }
}

:root {
  @include color-modifiers;
}

body {
  margin: 0;
  @extend .custom-scroll;
}

body,
.mat-card,
.mat-select,
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab,
button,
.mat-table,
.mat-expansion-panel-header,
.revenue-main,
.md-drppicker,
.mat-expansion-panel-content,
textarea {
  font-family: 'Nunito Sans', sans-serif;
}

.main-content {
  padding-bottom: 20px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

p {
  margin: 0;
}

.app-modal {
  z-index: 1002;
  background-color: $app-color-black;
}

app-side-nav,
.sidebar {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 9;
}

.ngb-dp-weekday {
  color: $app-color-black !important;
}

.form-control {
  &:focus {
    border-color: $client-view-clor15;
    box-shadow: 1px 1px $client-view-clor16 inset, 0px 0px 8px $client-view-clor17;
  }
}

.msg-error {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  // margin-top: 8px;
  color: $app-color-red-1;
}

.msg {
  &-warning {
    color: $client-view-clor18;
  }

  &-info {
    color: $app-color-blue;
  }

  &-info,
  &-warning {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
}

.mat-dialog-container {
  padding: 0 !important;
}

.main-dashboard {
  width: 100%;
  height: 100%;

  &-title {
    height: 41px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px !important;
    line-height: 24px;
    color: $app-color-gray-11;
    background-color: $app-color-white;
    -webkit-box-shadow: 0 8px 6px -6px $client-view-clor19;
    -moz-box-shadow: 0 8px 6px -6px $client-view-clor19;
    box-shadow: 0 8px 6px -6px $client-view-clor19;
    text-transform: uppercase;
    padding: 0 30px;

    & .breadcrumb-section {
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 27px;
      color: $app-color-gray-20;

      &-nav {
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 27px;
        color: $app-color-gray-20;
        text-transform: none;
      }

      & .active {
        color: $app-color-gray-11;
      }

      & img {
        width: 11px;
        height: 7px;
      }
    }
  }
}

.timelog-modal {
  .mat-menu-content:not(:empty) {
    max-height: 380px;
    width: 170px;
    overflow-x: auto;
    background: $client-view-clor22;
    @include draw-border(1px, solid, $client-view-clor23);
    box-shadow: 0px 4px 4px $client-view-clor20;
  }
}
.menu-list {
  .mat-menu-content:not(:empty) {
    max-height: 100%;
  }
}
.mat-menu-content:not(:empty) {
  // max-height: 150px;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-color: $unit-economics-clor17 $client-view-clor24;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: $client-view-clor24;
  }

  &::-webkit-scrollbar-thumb {
    width: 15px;
    background: $unit-economics-clor17;
  }
}
.mat-drawer.mat-drawer-end {
  max-width: 45%;
  min-width: 45%;
  width: 45%;
}
button.mat-menu-item {
  width: 150px !important;
  &.menu-item {
    width: 264px !important;
    background: #FFF;
    & .items {
      color: #505050;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &.delete {
        color: #FF0505;
      }
  }
  }
}
.divider {
  background: #E7E7E7;
  margin: 8px 0;
  min-height: 1px;
}
button.mat-button.add-task {
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2e3230;
}

.mat {
  &-drawer {
    &-end {
      max-width: 45%;
      min-width: 45%;
      width: 45%;
    }
  }
}

button {
  &.mat-menu-item {
    width: 150px !important;
  }
}

button {
  &.mat-button {
    &.add-task {
      padding: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $app-color-gray-11;
    }
  }
}


.timepicker-backdrop-overlay {
  z-index: 9999 !important;
}

.timepicker-overlay {
  z-index: 9999 !important;
}

.mat-form-field {
  width: 100%;
}

.ah-add-task {
  & .mat-dialog-container {
    overflow-x: hidden;
    @extend .custom-scroll;
  }
}

.mat-form-field-appearance-outline {
  & .mat-form-field-wrapper {
    margin: 0 !important;
    padding: 0;
  }
}

.table {
  .mat-form-field-appearance-outline {
    & .mat-form-field-infix {
      padding: 0;
      border-top: 0;
    }
  }

  input.mat-input-element,
  .mat-select-placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    height: 24px;
  }
}

.mat-form-field-appearance-outline {
  & .mat-form-field-infix {
    padding: 15px 0 10px 0;
    border-top: 0;

    & .grey {
      font-size: 14px;
      font-weight: 400;
      color: $unit-economics-clor1;
      line-height: 21px;
    }
  }
}


// .mat-form-field-type-mat-select:not(.mat-form-field-disabled)
.mat-form-field-flex {

  &:hover,
  &:focus-visible {
    outline: none;
  }
}

.mat-form-field-appearance-outline {
  & .mat-select-arrow-wrapper {
    margin-top: 10px;
  }
}


input {
  &.mat {
    &-input-element {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;

      &::placeholder {
        color: $unit-economics-clor1;
      }
    }

    &-select-placeholder {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;

      &::placeholder {
        color: $unit-economics-clor1;
      }
    }
  }
}


.mat-form-field-appearance-outline {
  & .mat-form-field-flex {
    padding: 0 0.25em 0 0.6em !important;
  }

}

.mat-menu-panel {
  &.create-task {
    margin-top: 10px !important;
  }
}

.mat-table {
  & thead {
    background: $client-view-clor25;
    border-radius: 6px;
  }
}

th {
  &.mat-header-cell {
    padding-right: 10px !important;
  }
}

td.mat {
  &-cell {
    padding-right: 10px !important;
  }

  &-footer-cell {
    padding-right: 10px !important;
  }
}


td {
  &.mat {
    &-cell {
      &.position {
        min-width: 10px;
      }

      &:first-of-type {
        min-width: 10px;
      }
    }

    &-header {
      &-cell:first-of-type {
        min-width: 10px;
      }
    }

    &-footer {
      &-cell:first-of-type {
        min-width: 10px;
      }
    }
  }
}

td {
  &.mat-cell {
    word-wrap: break-word;
  }
}

th {
  &.mat-header-cell {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 22px;
    color: $client-view-clor10;
  }

}

.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
}

td {
  &.mat-cell {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;
    color: $app-color-gray-2;

    &:first-of-type {
      padding-left: 24px !important;
    }
  }
}

.p-0 {
  & td.mat-cell {
    &:first-of-type {
      padding-left: 10px !important;
    }
  }
}

ah-time-picker,
ah-date-picker,
ah-input-autocomplete {
  width: 100%;
}


.mat-checkbox {
  &-ripple.mat-ripple-element {
    background-color: $app-color-blue !important;
  }

  &-checked {
    &.mat-accent {
      & .mat-checkbox {
        &-ripple {
          .mat-ripple-element {
            background-color: $app-color-blue !important;
          }
        }
      }

      &-background {
        background-color: $app-color-blue !important;
      }
    }

    &-checked,
    &-indeterminate.mat-accent {
      .mat-checkbox-background {
        background-color: $app-color-blue !important;
      }
    }
  }
}

.mat-form-field-appearance-outline {
  &:not(.mat-form-field-disabled) {
    & .mat-form-field-flex {
      &:hover {
        & .mat-form-field-outline-thick {
          opacity: 0.1 !important;
        }
      }
    }
  }
}

.mat-form-field-appearance-outline {
  & .mat-form-field {
    &-outline {
      &:focus-visible {
        outline: none;
      }
    }

    &-wrapper {
      &:focus-visible {
        outline: none;
      }
    }
  }
}

.timesheet-app {
  width: 100%;
  max-width: 428px;
  margin: 0 auto;

  & .button {
    height: 52px;

    &.blue-bg {
      background-color: $app-color-blue;
      color: $app-color-white !important;
    }

    &.back {
      @include draw-border(1px, solid, $app-color-gray-29);
    }

    &.reset {
      box-shadow: 0px 20px 80px $client-view-clor26;
      @include draw-border(1px, solid, $client-view-clor27);
    }
  }
}

ah-board-view {
  max-width: calc(25% - 24px);
}

mat-dialog-content {
  margin: 0 auto !important;
}

tr.mat-header-row {
  height: 45px !important;
}


.two-line-ellipsis,
.one-line-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.two-line-ellipsis {
  -webkit-line-clamp: 2;
}

.one-line-ellipsis {
  -webkit-line-clamp: 1;
}

.ng-dropdown-panel {
  & .scroll-host {
    position: inherit !important;
  }
}

.ah-router-modal {
  & .mat-dialog-container {
    max-width: none;
    border-radius: 0;
    background-color: rgba($color: $app-color-white, $alpha: 0.25);
    @include blur-background(5px);
    overflow-y: hidden;

    & .mat-dialog-content {
      max-height: 100%;

      @include breakpoint("xs") {
        padding: 0;
      }

      @include breakpoint("sm") {
        padding: 0;
      }
    }
  }
}

.ah-profile-modal {
  @include breakpoint("xs") {
    width: 100%;
  }

  @include breakpoint("sm") {
    width: 100%;
  }

  & .mat-dialog-container {
    max-width: none;
    border-radius: 0;
    background-color: $app-color-white;
    padding: 0px;

    & .mat-dialog-content {
      max-height: 100%;
      padding: 0px;
      margin: 0px;
    }
  }

  &.mat-dialog-container {
    padding: 0px;
  }
}

.cdk-overlay-pane {
  & .dynamic-column {
    max-width: 246px;
  }
  & .table-action {
    & .mat-menu-content {
      & .mat-menu-item {
        height: 35px;
        line-height: 35px;
      }
    }
  }

  & .select-popup {
    &.mat-menu-panel {
      min-height: 220px;

      & .mat-menu-content {
        min-height: calc(100% - 20px);
        width: 200px;
        padding: 5px;
        margin: auto;

        & .ng-dropdown {
          &-panel {
            &-items {
              max-height: 160px;
              @extend .custom-scroll-thin;
            }
          }
        }

      }
    }
  }

  & .select-popup-planning {
    &.mat-menu-panel {
      max-height: 250px;
      min-height: inherit;
      max-width: 300px;
      overflow-y: hidden;
      padding: 10px 2px;

      & .mat-menu-content {
        min-height: 250px;
        min-width: 240px;
        padding: 10px;
        margin: auto;

        & .ng-value {
          display: none;
        }


        & .ng-dropdown {
          &-panel {
            &-items {
              max-height: 180px;
              @extend .custom-scroll-custom
            }
          }
        }

        & .ng {

          &-clear,
          &-arrow {
            &-wrapper {
              display: none;
            }
          }
        }


      }
    }

    &.table {
      &.mat-menu-panel {
        & .mat-menu-content {
          & .ng-value {
            display: block;
          }

          & .ng-dropdown-panel {
            & .ng-dropdown-panel-items {
              max-height: 200px;
              @extend .custom-scroll-custom
            }
          }

        }
      }
    }
  }

  & .date-picker {
    &.mat-menu {
      &-panel {
        max-width: 300px;
        overflow-x: hidden;
        min-height: 250px;

        &-content {
          width: fit-content;
          min-height: 90%;
          overflow-y: hidden;
        }
      }
    }
  }

  & .menu-option-task {
    max-width: 285px;

    & .mat-menu-content {
      overflow-x: hidden;
      padding: 1px !important;
      min-height: 210px;
    }
  }

  & .inline-calendar-card {
    min-width: 300px;
    padding: 5px;

    & .mat-menu-content {
      width: 100%;
      overflow-x: hidden;
    }
  }
}

.menu-password-policy {
  position: absolute;
  right: 0;
  width: 222px;
  top: -98px;
  left: 450px;
  max-width: 325px;
  padding-left: 20px;
  font-size: 12px;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.15);
  @include draw-border(1px, solid, $client-view-clor27);

  &.instruction {
    padding: 0px;
    overflow: inherit;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: -10px;
      top: calc(45% - 5px);
      width: 0;
      height: 0;
      @include draw-border-top(10px, solid, transparent);
      @include draw-border-bottom(10px, solid, transparent);
      clear: both;
    }

    &::before {
      @include draw-border-right(10px, solid, $app-color-white);
    }

    &::after {
      @include draw-border-right(10px, solid, $client-view-clor28);
      left: -9px;
    }

    & .mat-menu-content {
      padding: 10px;

      & .text {

        &-danger,
        &-success {
          font-size: 10px;
          font-weight: 500;
          line-height: 25px;

          & span {
            margin-right: 5px;
          }
        }

        &-success {
          font-weight: 600;
        }
      }
    }
  }

}

.time-log-datepicker {
  & .mat-date-range-input-separator {
    display: none !important;
  }
}

.ng-select {
  width: 100% !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &.ng-select-single {
    & .ng-select-container {
      height: 40px !important;
    }
  }
}

.revenue-main {
  font-style: normal;
}

.md-drppicker {
  & th {
    &.month {
      background: $client-view-clor29 !important;
    }
  }

  &.inline {
    display: block !important;
    width: fit-content !important;
    margin: auto !important;
  }

  & .calendar {
    max-width: calc(50% - 8px) !important;
  }
}

.show-column {
  & .mat-checkbox-inner-container {
    width: 14px;
    height: 14px;
    margin: 0px 8px 0px 0px;
  }

  & .mat-checkbox-checked {
    & .mat-checkbox-checkmark {
      opacity: 1;
    }
  }
}

.mat-checkbox {
  &-indeterminate {
    &.mat-accent {
      & .mat-checkbox {
        &-background {
          background-color: $app-color-blue;
        }
      }
    }
  }

  &-checked {
    &.mat-accent {
      & .mat-checkbox {
        &-background {
          background-color: $app-color-blue;
        }
      }
    }
  }
}

::ng-deep {
  & .mat-checkbox {
    &-indeterminate {
      & .mat-checkbox-background {
        background-color: $app-color-black !important;
      }
    }

    &-checked {
      & .mat-checkbox-background {
        background-color: $app-color-black !important;
      }
    }

    &:not(.mat-checkbox-disabled) {
      & .mat-checkbox-ripple {
        & .mat-ripple-element {
          background-color: $app-color-black !important;
        }
      }
    }
  }
}

.timelog {
  &-panel {
    &.mat-expansion-panel:not([class*=mat-elevation-z]) {
      box-shadow: none;
    }

    &-radio {
      & .mat-radio-button.mat-accent {
        & .mat-radio-inner-circle {
          background-color: $app-color-blue;
        }

        & .mat-radio-ripple {
          & .mat-ripple-element {
            &:not(.mat-radio-persistent-ripple) {
              background-color: $app-color-blue;
            }
          }
        }

        &.mat-radio-checked {
          & .mat-radio-persistent-ripple {
            background-color: $app-color-blue;
          }
        }

        &:active {
          & .mat-radio-persistent-ripple {
            background-color: $app-color-blue;
          }
        }
      }

      & .mat-checkbox-indeterminate {
        &.mat-accent {
          & .mat-checkbox-background {
            background-color: $app-color-blue;
          }
        }
      }

      & .mat-checkbox-checked.mat-accent {
        &.mat-accent {
          & .mat-checkbox-background {
            background-color: $app-color-blue;
          }
        }
      }

      & .mat-radio-button.mat-accent {
        &.mat-accent.mat-radio-checked {
          & .mat-radio-outer-circle {
            @include draw-border(1px, solid, $app-color-blue);
          }
        }
      }

      & .mat-radio-button {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */
        color: $app-color-black;
      }

      & .mat-expansion-panel-spacing {
        margin: 0;
      }

      & .mat-expansion-panel {
        &-header {
          padding: 0;
        }

        &-body {
          padding: 0;
        }
      }

      .mat-expansion-panel-header {
        &.mat-expanded {
          height: auto;
          margin-bottom: 20px;
        }
      }

      &.mat-expansion-panel {
        & .mat-expansion-panel-header {
          &.cdk-keyboard-focused {
            &:not([aria-disabled=true]) {
              background: transparent;
            }
          }

          &.cdk-program-focused {
            &:not([aria-disabled=true]) {
              background: transparent;
            }
          }
        }

        &:not(.mat-expanded) {
          & .mat-expansion-panel-header {
            &:hover {
              &:not([aria-disabled=true]) {
                background: transparent;
              }
            }
          }
        }
      }


    }
  }
}

.mat-button {
  &:hover {
    &:not(.mat-button-disabled) {
      & .mat-button-focus-overlay {
        opacity: 0 !important;
      }
    }
  }
}

.mat-stroked-button {
  &:hover {
    &:not(.mat-button-disabled) {
      & .mat-button-focus-overlay {
        opacity: 0 !important;
      }
    }
  }
}


.show-filter {
  & .ng-select-container {
    margin-bottom: 5px;
  }

  & .ng-value {
    display: none;
  }

  & .ng-select {
    width: 100%;

    &.ng-select-opened {
      height: 100% !important;
    }

    & .ng-clear-wrapper,
    & .ng-arrow-wrapper {
      display: none;
    }
  }

  & .ng-dropdown-panel {
    position: inherit;
    border: 0;
    @extend .custom-scroll-thin;

    & .ng-dropdown-panel-items {
      max-height: 100%;
    }

    &.ng-select-bottom {
      margin-top: 0;
      border: none;
    }
  }

  & .ng-option {
    text-align: left !important;
  }
}

.ng-option {
  text-transform: capitalize;
}

.mat-expansion-panel {
  &-body {
    padding: 0 !important;
  }

  &-header {
    padding: 0 !important;
  }
}

.user-assignee {
  width: 100%;
  height: 100%;

  &-circle {
    width: 100%;
    height: 100%;
    color: $client-view-clor22;
    font-size: 11px;
    font-weight: 700;
    background: $app-color-gray-31;
    border-radius: 50%;
    @include draw-border(1px, solid, $client-view-clor22);
    overflow: hidden;
  }
}

.list-tab {
  & .mat-tab-label {
    min-width: fit-content;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    color: $client-view-clor30;
    padding: 0 18px;

    &-active {
      color: $app-color-blue;
      opacity: 1;
    }
  }

  &-table {
    width: 100%;
  }

  &.mat-tab-group.mat-primary {
    & .mat-ink-bar {
      background-color: $app-color-blue;
    }
  }

  &.mat-tab-nav-bar.mat-primary {
    & .mat-ink-bar {
      background-color: $app-color-blue;
    }
  }
}

.expense {
  &-category {

    & .show {
      &-category {
        & .ng-select {
          width: 100%;
          padding-bottom: 10px;

          &.ng-select-opened {
            height: 100% !important;
          }

          & .ng-clear-wrapper,
          & .ng-arrow-wrapper {
            display: none;
          }
        }

        & .ng-dropdown-panel {
          position: inherit;
          border: 0;
          @extend .custom-scroll-thin;

          & .ng-dropdown-panel-items {
            max-height: 100%;
          }

          &.ng-select-bottom {
            margin-top: 0;
            border: none;
          }
        }

        & .ng-option {
          text-align: left !important;
        }
      }

      &-expense {
        & .ng-select {
          width: 100%;
          padding-bottom: 10px;

          &.ng-select-opened {
            height: 100% !important;
          }

          & .ng-clear-wrapper,
          & .ng-arrow-wrapper {
            display: none;
          }
        }

        & .ng-dropdown-panel {
          position: inherit;
          border: 0;
          @extend .custom-scroll-thin;

          & .ng-dropdown-panel-items {
            max-height: 100%;
          }

          &.ng-select-bottom {
            margin-top: 0;
            border: none;
          }
        }

        & .ng-option {
          text-align: left !important;
        }
      }
    }

  }
}

.show-date-range-picker {
  position: absolute;
  top: 40px;
  z-index: 999;
  width: max-content;
  margin: 0 auto;
  text-align: left;
  background: $app-color-white;

  & .create {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    color: $unit-economics-clor17;
    margin-top: 10px;
  }
}

.ng-dropdown-panel-items {
  width: 100%;
}

.calender-picker {
  & .mat-calendar-body-label {
    opacity: 0;
  }

  & .mat-calendar-body-label[colspan="7"] {
    display: none;
  }
}

.complete-popup-content {
  & .mat-dialog-content {
    max-width: 100%;
    height: 100%;
  }
}

.ng-optgroup {
  color: $client-view-clor14 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  padding-left: 0px !important;
  padding-top: 15px !important;
}

.mat-checkbox-inner-container {
  height: 13px !important;
  width: 13px !important
}

.mat-expansion-panel-content {
  background: $unit-economics-clor18 !important;
}

.ng-option {
  & .ng-option-child {
    padding-left: 0px !important;
  }
}

.mat-slide-toggle-bar {
  margin-right: 10px !important;
  position: relative !important;
}

.mat-slide-toggle-thumb {
  height: 12px !important;
  width: 12px !important;
  position: absolute;
  top: 4px !important;
  left: 2px !important;
}

.mat-slide-toggle-bar {
  width: 32px !important;
}

.mat-slide-toggle {
  &.mat-checked {
    & .mat-slide-toggle-bar {
      background-color: $unit-economics-clor17 !important;
    }

    & .mat-slide-toggle-thumb {
      background-color: $app-color-white !important;
    }
  }
}

.no-select {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE/Edge */
  user-select: none;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}

.backlog {
  & .mat-icon-button {
    width: 24px;
  }
  & .mat-form-field-infix {
    width: 110px;
}
  &.mat-form-field {
    &-appearance-outline {
      & .mat-form-field-outline {
        color: #fff !important;
      }
      & .mat-form-field-flex {
        padding: 0 !important;
      }
      & .mat-form-field-infix {
        display: flex;
        align-items: center;
        height: 36px;
        position: relative;
        top: 2px;
      }
    }
  }
  & mat-icon.mat-icon.notranslate.material-icons.mat-ligature-font.mat-icon-no-color, & .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
  }
}
.border-error {
  width: 100%;

  & .ng-select-container {
    @include draw-border-left(2px, solid, $unit-economics-clor3 !important);
  }
}
.backlog-table-view {
  & .avatar-content {
    font-size: 16px !important;
  }

}

.popup-to-multi-select {
  & .ng-dropdown-panel {
    & .scroll-host {
      position: absolute !important;
      top: 65px;
      width: 300px;
      box-shadow:
        2px 0 6px -2px rgba(0, 0, 0, 0.08),
        -2px 0 6px -2px rgba(0, 0, 0, 0.08);
    }
  }

  & .ng-dropdown-panel {
    & .ng-dropdown-panel-items {
      & .ng-optgroup.ng-option {
        &-disabled {
          background-color: $app-color-white;
          padding-left: 15px !important;
        }
      }
    }
  }

  & .ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
    width: 220px;

  }

  & .ng-star-inserted {
    font-style: normal;
    line-height: normal;
    font-weight: 400;

    & .project-name-main {
      color: $client-view-clor10;
      font-size: 14px;
    }

    & .project-name {
      color: $client-view-clor10;
      font-size: 10px;
      padding-left: 4px;
    }
  }

  & .ng-dropdown-panel {
    border: none;
  }

  & .ng-dropdown-panel {
    & .ng-dropdown-panel-items {
      & .ng-option.ng-option-child {
        padding-left: 40px;
      }
    }
  }

}

.multi-select-overlay-class {
  position: absolute !important;
  padding: 15px;
  background: $app-color-white;
  box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, 0.2), 0 -8px 10px 1px rgba(0, 0, 0, 0.1411764706), 0 -3px 14px 2px rgba(0, 0, 0, 0.1215686275);

  & .show-category {
    width: 270px;
  }

  & .create {
    position: absolute;
    top: 304px;
    left: 0px;
    width: 270px;
    background: $app-color-white;
    padding: 10px 10px 10px 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    color: $app-color-blue;
    box-shadow:
      2px 0 6px -2px rgba(0, 0, 0, 0.08),
      -2px 0 6px -2px rgba(0, 0, 0, 0.08),
      0 2px 6px -2px rgba(0, 0, 0, 0.08);
  }
}

.untagged-class {
  bottom: 60vh;
  left: 41px;
}
