@import "./variables.scss";
.custom-scroll {
    scrollbar-color: $app-color-friargray $app-color-hitgray;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: $app-color-hitgray;
    }
    &::-webkit-scrollbar-thumb {
        width: 15px;
        background: $app-color-friargray;
    }
    &-mini {
        scrollbar-color: $app-color-friargray $app-color-hitgray;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background-color: $app-color-friargray;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $app-color-friargray;
            outline: 1.5px solid $app-color-friargray;
        }
        &::-moz-scroller {
            width: 10px;
        }
        &::-moz-scroller-track {
            background-color: $app-color-friargray;
        }
        &::-moz-scroller-thumb {
            background-color: $app-color-friargray;
            outline: 1.5px solid $app-color-friargray;
        }
    }
    &-thin {
        scrollbar-color: $app-color-friargray $app-color-hitgray;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            width: 2px;
        }
        &::-webkit-scrollbar-track {
            background-color: $app-inactive-color;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $app-color-friargray;
            outline: 1.5px solid $app-color-friargray;
        }
        &.horizontal {
            &::-webkit-scrollbar {
                margin-top: 10px;
                height: 3px;
                cursor: pointer;
            }
            &::-webkit-scrollbar-thumb {
                background: #C8C8C8;
                border-radius: 50px;
                outline: 1.5px solid #C8C8C8;
            }
            &::-webkit-scrollbar-track {
                background-color: $app-color-white;
            }
            &:hover {
                &::-webkit-scrollbar {
                    height: 8px;
                }
            }
        }
    }
    &-custom {
      scrollbar-color: $app-color-friargray $app-color-hitgray;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        margin-top: 10px;
        width: 8px;
        cursor: pointer;
      }
      &::-webkit-scrollbar-track {
          background-color: $app-color-white;
      }
      &::-webkit-scrollbar-thumb {
        background: #C8C8C8;
        border-radius: 50px;
        outline: 1.5px solid #C8C8C8;
      }
      &.horizontal {
          &::-webkit-scrollbar {
              margin-top: 10px;
              height: 3px;
              cursor: pointer;
          }
          &::-webkit-scrollbar-thumb {
              background: #C8C8C8;
              border-radius: 50px;
              outline: 1.5px solid #C8C8C8;
          }
          &::-webkit-scrollbar-track {
              background-color: $app-color-white;
          }
          &:hover {
              &::-webkit-scrollbar {
                  height: 8px;
              }
          }
      }
  }
}
